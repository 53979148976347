function require_purchase_orders_checked() {
  return $('#application_require_purchase_orders_true')[0].checked;
}

function fire_resistance_checked() {
  return (
    $('#application_fire_door_installation_true')[0].checked ||
    $('#application_fire_door_maintenance_true')[0].checked ||
    $('#application_fire_door_inspection_true')[0].checked
  );
}

function schemesCheched() {
  return (
    $('#application_windows_and_doorsets_aluminium')[0].checked ||
    $('#application_windows_and_doorsets_pvcu')[0].checked ||
    $('#application_windows_and_doorsets_steel')[0].checked ||
    $('#application_windows_and_doorsets_timber')[0].checked ||
    $('#application_external_doors_or_windows_and_conservatories_true')[0]
      .checked ||
    $('#application_security_doorsets')[0].checked ||
    $('#application_security_windows')[0].checked ||
    $('#application_noise_reducing_windows_and_doorsets_true')[0].checked ||
    $('#application_insulating_glass_units_bs_en_1279-2')[0].checked ||
    $('#application_insulating_glass_units_bs_en_1279-3')[0].checked ||
    $('#application_safety_glass_toughened')[0].checked ||
    $('#application_safety_glass_heat_soak_toughened')[0].checked ||
    $('#application_safety_glass_laminated')[0].checked ||
    $('#application_fire_resisting_doors_and_windows_true')[0].checked ||
    $('#application_doorsets_or_gates_for_panic_exit_true')[0].checked ||
    $('#application_fire_door_manufacture_true')[0].checked ||
    $('#application_fire_door_installation_true')[0].checked ||
    $('#application_fire_door_maintenance_true')[0].checked ||
    $('#application_fire_door_inspection_true')[0].checked ||
    $('#application_penetration_seals_true')[0].checked ||
    $('#application_fire_resistant_door_manufacture_true')[0].checked
  );
}

function switchFields() {
  value = this.value;
  checked = this.checked;

  if (value == 1 && checked == true) {
    list = document.getElementsByClassName('activity_schemes_field');
    for (let item of list) {
      item.classList.remove('hidden');
    }
  } else if (value == 1 && checked == false) {
    list = document.getElementsByClassName('activity_schemes_field');
    for (let item of list) {
      item.classList.add('hidden');
    }
    if (!$('#application_application_type_2')[0].checked) {
      $('#application_application_type_2').click();
    }
  } else if (value == 2 && checked == true) {
    list = document.getElementsByClassName('manufacturing_schemes_field');
    for (let item of list) {
      item.classList.remove('hidden');
    }
  } else if (value == 2 && checked == false) {
    list = document.getElementsByClassName('manufacturing_schemes_field');
    for (let item of list) {
      item.classList.add('hidden');
    }
    if (!$('#application_application_type_1')[0].checked) {
      $('#application_application_type_1').click();
    }
  }
}

function iso_or_ce_certified_true_changed() {
  document
    .getElementById('certification_info_field')
    .classList.remove('hidden');
}
function iso_or_ce_certified_false_changed() {
  document.getElementById('certification_info_field').classList.add('hidden');
}

function fire_resistance_changed() {
  if (fire_resistance_checked()) {
    document
      .getElementById('number_of_supervisors_group')
      .classList.remove('hidden');
  } else {
    document
      .getElementById('number_of_supervisors_group')
      .classList.add('hidden');
  }
}

function require_purchase_orders_changed() {
  if (require_purchase_orders_checked()) {
    document
      .getElementById('po_number_for_initial_fees_group')
      .classList.remove('hidden');
  } else {
    document
      .getElementById('po_number_for_initial_fees_group')
      .classList.add('hidden');
  }
}

function accepted_terms() {
  const iAccept = document.getElementById('i_accept');
  if (iAccept != null) {
    return iAccept.checked;
  } else {
    return true;
  }
}

document.addEventListener('turbolinks:load', () => {
  if (document.body.id == 'submissions_new_application') {
    const inputElement_1 = document.getElementById(
      'application_application_type_1'
    );
    inputElement_1.addEventListener('change', switchFields, false);

    const inputElement_2 = document.getElementById(
      'application_application_type_2'
    );
    inputElement_2.addEventListener('change', switchFields, false);

    const iso_or_ce_certified_true_field = document.getElementById(
      'application_iso_or_ce_certified_true'
    );
    if (iso_or_ce_certified_true_field != null) {
      iso_or_ce_certified_true_field.addEventListener('change', () =>
        iso_or_ce_certified_true_changed()
      );
    }

    const iso_or_ce_certified_false_field = document.getElementById(
      'application_iso_or_ce_certified_false'
    );
    if (iso_or_ce_certified_false_field != null) {
      iso_or_ce_certified_false_field.addEventListener('change', () =>
        iso_or_ce_certified_false_changed()
      );
    }

    const fire_resistance_fields_ids = [
      'application_fire_door_installation_true',
      'application_fire_door_maintenance_true',
      'application_fire_door_inspection_true',
      'application_fire_door_installation_false',
      'application_fire_door_maintenance_false',
      'application_fire_door_inspection_false',
    ];

    fire_resistance_fields_ids.forEach((item) => {
      if (document.getElementById(item) != null) {
        document
          .getElementById(item)
          .addEventListener('change', () => fire_resistance_changed());
      }
    });

    const po_fields_ids = [
      'application_require_purchase_orders_true',
      'application_require_purchase_orders_false',
    ];

    po_fields_ids.forEach((item) => {
      if (document.getElementById('po_number_for_initial_fees_group') != null) {
        document
          .getElementById(item)
          .addEventListener('change', () => require_purchase_orders_changed());
      }
    });

    var event = new Event('change');
    inputElement_1.dispatchEvent(event);

    $('#submit_application_form').on('submit', function (e) {
      if (!accepted_terms()) {
        e.preventDefault();
        $('#submit_application').addClass('shake');
        $('.tc_accept_warning').removeClass('hidden');
        $('.check_one_scheme_warning').addClass('hidden');
      } else if (!schemesCheched()) {
        e.preventDefault();
        $('#submit_application').addClass('shake');
        $('.tc_accept_warning').addClass('hidden');
        $('.check_one_scheme_warning').removeClass('hidden');
      }

      setTimeout(() => {
        $('#submit_application').removeClass('shake');
        document.getElementById('submit_application').disabled = false;
      }, 2000);
    });
    
    fire_resistance_changed();
  }
});
