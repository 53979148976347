function switchFields() {
  // resetInputs()
  value = this.options[this.selectedIndex].value

  if(value == 1) {
    list = document.getElementsByClassName('manufacturing_schemes_field')
    for (let item of list) {
      item.classList.add("hidden")
    }
    list = document.getElementsByClassName('activity_schemes_field')
    for (let item of list) {
      item.classList.remove("hidden")
    }
  } else {
    list = document.getElementsByClassName('manufacturing_schemes_field')
    for (let item of list) {
      item.classList.remove("hidden")
    }
    list = document.getElementsByClassName('activity_schemes_field')
    for (let item of list) {
      item.classList.add("hidden")
    }
  }
}

document.addEventListener('turbolinks:load', () => {
  if (document.body.id == "quotes_new" || document.body.id == "quotes_edit") {
    const inputElement = document.getElementById("quote_quote_type");
    inputElement.addEventListener("change", switchFields, false);

    var event = new Event('change');
    inputElement.dispatchEvent(event);
  }
})
