function handleFiles() {
  resetInputs()

  document.getElementById("submit_docs").disabled = false;
  Array.from(this.files).forEach(file => createFieldsFor(file));

  setDefaultCategory()
}

function setDefaultCategory() {
  let value = document.querySelector('#documents .nav-link.active').dataset.id;
  document.getElementById('document_types').value = value

  changeAllDocTypes()
}

function resetInputs() {
  let doc_fields = document.getElementsByClassName("doc_fields")
  doc_fields[doc_fields.length - 1].classList.add("hidden")
  doc_fields[doc_fields.length - 1].getElementsByClassName("doc_category_input")[0].value = 0

  let cloned_fields = document.getElementsByClassName("cloned")
  for (var i = cloned_fields.length - 1; i >= 0; --i) {
    cloned_fields[i].remove();
  }
}

function createFieldsFor(file) {
  let doc_fields = document.getElementsByClassName("doc_fields")
  let type_input = doc_fields[doc_fields.length - 1];

  if (type_input.classList.contains('hidden')) {
    type_input.classList.remove("hidden")
    current_inputs = type_input
  } else {
    let current_inputs = type_input.cloneNode(true);
    current_inputs.classList.add("cloned")
    type_input.before(current_inputs);
  }

  // show global doc type select
  document.getElementsByClassName("global_document_type")[0].classList.remove("hidden")
  document.getElementsByClassName("doc_submit_block")[0].classList.remove("hidden")

  current_inputs.getElementsByClassName("doc_name_input")[0].value = file.name
}

function changeAllDocTypes() {
  let selects = document.querySelectorAll(".doc_fields select");
  let inputDocType = document.getElementById("document_types");

  [].forEach.call( selects, function(select) {
	  select.value = inputDocType.value;
	});
}

// reuired to add [] in the end of input name, but unable to make it via rails helper multiple="true"
// it just creates select with multiple options
function fixDocTypeInputs() {
  let doc_category_inputs = document.getElementsByClassName("doc_category_input")
  if (doc_category_inputs.length > 0) {
    let doc_category_input = doc_category_inputs[0]
    doc_category_input.name = doc_category_input.name + '[]'
  }
}
document.addEventListener('turbolinks:load', () => {
  if (document.body.id == "companies_show") {
    fixDocTypeInputs();
    const inputElement = document.getElementById("doc_file_input");
    const inputDocTypes = document.getElementById("document_types");
    inputElement.addEventListener("change", handleFiles, false);
    inputDocTypes.addEventListener("change", changeAllDocTypes, false);
  }
})
