function switchSubmitTitle() {
  // resetInputs()
  checked = this.checked

  if(checked) {
    document.getElementById("submit_item").classList.add('btn-primary')
    document.getElementById("submit_item").classList.remove('btn-secondary')
    document.getElementById("submit_item").value = "Add to scope"
  } else {
    document.getElementById("submit_item").classList.remove('btn-primary')
    document.getElementById("submit_item").classList.add('btn-secondary')
    document.getElementById("submit_item").value = "Save draft"
  }
}

function switchTechReviewSubmitTitle() {
  // resetInputs()
  checked = this.checked

  if(checked) {
    document.getElementById("approve_item").classList.add('btn-primary')
    document.getElementById("approve_item").classList.remove('btn-secondary')
    document.getElementById("approve_item").value = "Request Authorisation"
  } else {
    document.getElementById("approve_item").classList.remove('btn-primary')
    document.getElementById("approve_item").classList.add('btn-secondary')
    document.getElementById("approve_item").value = "Save"
  }
}

document.addEventListener('turbolinks:load', () => {
  if (document.body.id == "items_new" || document.body.id == "items_edit") {
    const inputElement = document.getElementById("ready_for_review");
    if (inputElement != undefined) {
      inputElement.addEventListener("change", switchSubmitTitle, false);
      var event = new Event('change');
      inputElement.dispatchEvent(event);
    }

    const techReviewApprove = document.getElementById("can_be_approved");
    if (techReviewApprove != undefined) {
      techReviewApprove.addEventListener("change", switchTechReviewSubmitTitle, false);
      var event = new Event('change');
      techReviewApprove.dispatchEvent(event);
    }


  }
})
