function validatePassword(e){
  let pswd = e.target.value
  //validate the length
  if (pswd.length < 8) {
    $('#length').removeClass('text-success').addClass('text-warning');
    $('.length-check').addClass('hidden')
    $('.length-x').removeClass('hidden')
  } else {
    $('#length').removeClass('text-warning').addClass('text-success');
    $('.length-check').removeClass('hidden')
    $('.length-x').addClass('hidden')
  }

  //validate capital letter
  if (pswd.match(/[A-Z]/)) {
    $('#capital').removeClass('text-warning').addClass('text-success');
    $('.capital-check').removeClass('hidden')
    $('.capital-x').addClass('hidden')
  } else {
    $('#capital').removeClass('text-success').addClass('text-warning');
    $('.capital-check').addClass('hidden')
    $('.capital-x').removeClass('hidden')
  }

  //validate special character
  if (pswd.match(/[!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~]/)) {
    $('#character').removeClass('text-warning').addClass('text-success');
    $('.character-check').removeClass('hidden')
    $('.character-x').addClass('hidden')
  } else {
    $('#character').removeClass('text-success').addClass('text-warning');
    $('.character-check').addClass('hidden')
    $('.character-x').removeClass('hidden')
  }

  //validate number
  if (pswd.match(/\d/)) {
    $('#number').removeClass('text-warning').addClass('text-success');
    $('.number-check').removeClass('hidden')
    $('.number-x').addClass('hidden')
  } else {
    $('#number').removeClass('text-success').addClass('text-warning');
    $('.number-check').addClass('hidden')
    $('.number-x').removeClass('hidden')
  }
}

document.addEventListener('turbolinks:load', () => {
  if (document.body.id == "submissions_new_user") {
    const user_password = document.getElementById("user_password");
    user_password.addEventListener("input", validatePassword, false);
  }
})
