function terms_changed(termsCheckBox1, termsCheckBox2, termsCheckBox3) {
  //If the checkbox has been checked
  if (
    termsCheckBox1.checked &&
    termsCheckBox2.checked &&
    termsCheckBox3.checked
  ) {
    //Set the disabled property to FALSE and enable the button.
    document.getElementById('accept_quote').disabled = false;
    $('#accept_quote').removeClass('btn-secondary');
    $('#accept_quote').addClass('btn-primary');
    $('.tc_accept_warning').addClass('hidden');
  } else {
    //Otherwise, disable the submit button.
    $('#accept_quote').addClass('btn-secondary');
    $('#accept_quote').removeClass('btn-primary');
    $('.tc_accept_warning').removeClass('hidden');
    document.getElementById('accept_quote').disabled = true;
  }
}

document.addEventListener('turbolinks:load', () => {
  if (document.body.id == 'quotes_show') {
    const iAccept1 = document.getElementById('i_accept_1');
    const iAccept2 = document.getElementById('i_accept_2');
    const iAccept3 = document.getElementById('i_accept_3');
    if (iAccept1 != null) {
      iAccept1.addEventListener('change', () =>
        terms_changed(iAccept1, iAccept2, iAccept3)
      );
    }
    if (iAccept2 != null) {
      iAccept2.addEventListener('change', () =>
        terms_changed(iAccept1, iAccept2, iAccept3)
      );
    }
    if (iAccept3 != null) {
      iAccept3.addEventListener('change', () =>
        terms_changed(iAccept1, iAccept2, iAccept3)
      );
    }

    $('#accept_form').on('submit', function (e) {
      if (
        iAccept1.checked == false ||
        iAccept2.checked == false ||
        iAccept3.checked == false
      ) {
        e.preventDefault();
        $('#accept_quote').addClass('shake');
        $('.tc_accept_warning').removeClass('hidden');

        return false;
      }
    });

    const checkboxes = [
      '#all_information_provided',
      '#application_in_line_with_quote',
      '#proposed_scope_achievable',
      '#contract_can_be_fulfilled',
    ].map((id) => $(id));
    const finish_and_approve = $('#finish_and_approve');

    checkboxes.forEach((checkbox) =>
      checkbox.on('change', () => {
        finish_and_approve.prop(
          'disabled',
          !checkboxes.every((ch) => ch.is(':checked'))
        );
      })
    );

    finish_and_approve.prop(
      'disabled',
      !checkboxes.every((ch) => ch.is(':checked'))
    );
  }
});
